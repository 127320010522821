<template>
    <div ref="main-container">
        <div>
            <b-row class="mb-2">
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <h1 class="mb-0">{{i18nT(`Etat des lieux`)}}</h1>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-form-group
                    >
                        <b-form-radio-group
                            v-model="listMode"
                            button-variant="outline-secondary"
                            name="radio-btn-outline"
                            buttons
                            class="ren-active-buttons"
                        >
                            <b-form-radio value="active"  >
                                {{ i18nT(`Actifs`)}}
                                <b-badge :variant="listMode == 'active' ? 'light-primary' : 'light-secondary' " class="ml-50 text-primary">{{ activeInventoriesCount }}</b-badge>
                            </b-form-radio>
                            <b-form-radio value="archive" >{{i18nT(`Archives`)}}
                                <b-badge class="ml-50 text-primary" :variant="listMode == 'archive' ? 'light-primary' : 'light-secondary'">{{ archiveInventoriesCount }}</b-badge>
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-dropdown
                        variant="primary"
                        class="float-right pl-1 pb-1"
                    >
                        <template #button-content>
                            <RtIcon variant="light" icon="plus-circle mr-50" />
                            {{ i18nT(`Nouvel état des lieux`) }}
                        </template>

                        <b-dropdown-item class="text-primary"
                                         :to="{ name: 'create-handover', }"
                        >
                            <div class=''>
                                <RtIcon variant="light" icon="plus-circle mr-50" />
                                {{ i18nT(`Nouvel état des lieux`) }}
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{name: 'import-tenants'}"
                        >
                            <div>
                                <RtIcon variant="light" icon="file-import mr-50" />
                                {{ i18nT(`Importer`) }}
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
        </div>
        <b-card no-body>
            <b-card-body>
                <div>

                    <b-row>
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-form-group :label="i18nT(`Propriétaires`)">
                                <rt-select
                                    v-model="inventoryLandlord"
                                    :options="inventoryLandlords"
                                    label="text"
                                    :selectable="selected => selected.disabled !== true"
                                    :placeholder="i18nT(`Tous les propriétaires`)"
                                    :closeOnSelect="false"
                                    multiple
                                    deselectFromDropdown
                                    :persistentPlaceholder="['Propriétaire', 'Propriétaires']"
                                    :reduce="(e) => e.value"
                                    id='inventoryLandlord'
                                    icon="user-check"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-form-group :label="i18nT(`Bien`)">
                                <rt-select
                                    v-model="inventoryProperty"
                                    :options="inventoryProperties"
                                    label="text"
                                    :selectable="selected => selected.disabled !== true"
                                    :placeholder="i18nT(`Tous les biens`)"
                                    :closeOnSelect="false"
                                    multiple
                                    deselectFromDropdown
                                    :persistentPlaceholder="['Immeuble', 'Immeubles']"
                                    :reduce="(e) => e.value"
                                    id='inventoryProperty'
                                    icon="building"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-form-group :label="i18nT(`Type`)">
                                <rt-select
                                    v-model="inventoryType"
                                    :options="inventoryTypes"
                                    label="text"
                                    :selectable="selected => selected.disabled !== true"
                                    :placeholder="i18nT(`Tous type`)"
                                    deselectFromDropdown
                                    :reduce="(e) => e.value"
                                    :clearable="false"
                                    id='inventoryType'
                                    icon="door-open"
                                />
                            </b-form-group>
                        </b-col>
                        <div class="ml-50">
                            <rt-all-filters @removeFilter="removeFilter" @clearFilters="clearFilters"/>
                        </div>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
        <!--                TABLE AND SEARCH-->
        <b-card no-body>
            <div>
                <b-row class="mx-2 mt-2">
                    <b-col
                        cols="12"
                        md="7"
                        sm="6"
                        class="mb-1 pl-0"
                    >
                        <v-select
                            v-model="perPage"
                            :clearable="false"
                            :options="perPageOptions"
                            class="per-page-selector d-inline-block"
                        />
                        <b-dropdown
                            v-if="selectedItems.length > 0"
                            class="d-inline ml-1"
                            size="md"
                            text="Actions"
                            variant="outline-secondary"
                        >
                            <b-dropdown-item @click="onBulkArchive()">
                                <RtIcon :size="16" variant="light" icon="box-archive"/>
                                <span class="align-middle ml-50">{{ i18nT(`Archiver la sélection`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onBulkDelete()">
                                <span class="text-danger"><RtIcon :size="16" variant="light" icon="trash"/></span>
                                <span
                                    class="align-middle ml-50 text-danger"
                                >{{
                                        i18nT(`Supprimer`)
                                    }}</span>
                            </b-dropdown-item>
                        </b-dropdown>

                    </b-col>
                    <!--                            search-input-->
                    <b-col
                        cols="12"
                        md="5"
                        sm="6"
                        class="mb-1 pr-0 d-flex justify-content-end"
                    >
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="input-group input-group-merge" role="group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <RtIcon variant="light" icon="search"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Chercher`)"
                                        class="d-inline-block"
                                        type="text"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <b-table
                    ref="refInventoriesListTable"
                    v-model="currentItems"
                    :key="'InventoryTable' + tableColumns.length"
                    :empty-html="emptyHtml"
                    :fields="computedColumns"
                    :items="fetchInventories"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    primary-key="id"
                    responsive
                    show-empty
                    @refreshed="onTableRefresh"
                >
                    <!--                        TODO - add template cells-->
                    <template v-slot:head(checkbox)="data">
                        <span>{{data.label.toUpperCase()}}</span>
                        <b-form-checkbox
                            v-model="selectAllStatus"
                            @change="onSelectAll"
                        />
                    </template>

                    <template #head(action)="data">
                        <span>{{data.label.toUpperCase()}}</span>
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="true"
                                v-b-tooltip="i18nT(`Colonnes`)"
                            >
                                <template #button-content>
                                    <RtIcon variant="light" icon="gear text-body ren-lh-22" size="18px"/>
                                </template>

                                <div class="pl-1 pr-1"
                                     v-for="(field, index) in availableFields"
                                     :key="'Units - ' + index"
                                >
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{i18nT(field.label)}}</b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>

                    <template #cell(checkbox)="data">
                        <b-form-checkbox
                            v-model="selectedItems"
                            :value="data.item.LeaseHandoverID"
                            class="pl-0"
                        />
                    </template>

                    <template #cell(HandoverIdentifiant)="data">
                        <div class="d-flex text-nowrap">
                            <b-link
                                :to="{name: 'edit-handover', params:{id: data.item.LeaseHandoverID}}"
                                class="mb-0 font-weight-bold d-block text-nowrap ren-link"
                            >
                                {{ data.item.Name }}
                            </b-link>
                            <b-badge
                                :variant="data.item.LeaseHandoverSignStarted && data.item.LeaseHandoverSignDate
                                ? 'light-info' : data.item.LeaseHandoverSignStarted && !data.item.LeaseHandoverSignDate
                                ? 'light-warning' : 'light-secondary'"
                                class="badge-pill"
                            >
                                <span v-if="data.item.LeaseHandoverSignStarted">
                                    <rt-icon icon="lock" variant="light" :size="12" class="mr-50"/>
                                </span>
                                {{ getBadgeTitle(data.item) }} {{data.item.LeaseHandoverSignDate | date}}
                            </b-badge>
                        </div>
                    </template>

                    <template #cell(HandoverType)="data">
                        <p class="m-0 text-nowrap">{{data.item.HandoverType === "enter" ? i18nT(`Etat des lieux d'entrée`) : i18nT(`Etat des lieux de sortie`)}}</p>
                    </template>

                    <template #cell(PropertyTitle) = "data">
                        <div v-if="data.item.PropertyTitle">
                            <b-link
                                class="mr-1 d-flex ren-property cursor-pointer text-nowrap"
                                :to="{name:'view-property', params:{id: data.item.PropertyID}}"
                            >
                                {{ data.item.PropertyTitle }}
                            </b-link>
                        </div>
                        <div v-else>
                            <b-badge variant="light-warning" class="badge-pill">
                                {{i18nT(`Pas de bien lié`)}}
                            </b-badge>
                        </div>
                    </template>

                    <template #cell(tenant)="data">
                        <div class="d-flex" v-if="data.item.Tenants.length > 0">
                            <b-link
                                    v-for="(tenant, index) in data.item.Tenants"
                                    :key="'tenant'+index"
                                    class="mr-1 d-flex ren-tenant cursor-pointer text-nowrap"
                                    :to="{name:'view-tenant', params:{id: tenant.TenantID}}"
                            >
                                {{ tenant.TenantFirstName }} {{ tenant.TenantLastName }}
                            </b-link>
                        </div>
                        <div v-else>
                            <b-badge variant="light-warning" class="badge-pill">
                                {{i18nT(`Pas de locataires`)}}
                            </b-badge>
                        </div>
                    </template>

                    <template #cell(templates)="data">
                        <b-dropdown
                            toogle-class="p-0"
                            variant="outline-secondary"
                            size="sm"
                        >
                            <template #button-content>
                                {{i18nT('Modèle')}}
                            </template>

                            <b-dropdown-item v-if="data.item.LeaseHandoverSignDate">
                                <span>
                                    <RtIcon variant="light" icon="signature mr-50 text-body"/>
                                </span>
                                <span>{{i18nT(`Télécharger le document signé`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="openDownloadModal(data.item)">
                                <span>
                                    <RtIcon variant="light" icon="download mr-50 text-body"/>
                                </span>
                                <span>{{i18nT(`Télécharger état des lieux`)}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                    <template #cell(action)="data">
                        <div class="d-flex justify-content-center">
                            <b-link
                                :to="{name:'edit-handover', params:{id: data.item.LeaseHandoverID}}"
                                class="text-secondary"
                                v-b-tooltip.hover.top="i18nT(`Modifier`)"
                                :disabled="data.item.LeaseHandoverSignStarted ? true : false"
                                :class="data.item.LeaseHandoverSignStarted  ? 'text-muted' : ''"
                            >
                                <RtIcon variant="light" icon="edit mr-1" :size="15"/>
                            </b-link>
                            <b-link
                                :to="{name:'edit-handover', params:{id: data.item.LeaseHandoverID}}"
                                class="text-secondary"
                                v-b-tooltip.hover.top="i18nT(`Voir`)"
                            >
                                <RtIcon variant="light" icon="eye mr-1" :size="15"/>
                            </b-link>
                            <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                                dropleft
                            >
                                <template #button-content>
                                    <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1"
                                            :size="18"/>
                                </template>
                                <b-dropdown-item
                                    :to="{name:'edit-handover', params:{id: data.item.LeaseHandoverID}}"
                                    v-if="!(data.item.LeaseHandoverSignStarted && data.item.LeaseHandoverSignDate)"
                                >
                                    <RtIcon variant="light" icon="edit"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="onArchive(data)"
                                >
                                    <RtIcon variant="light" icon="box-archive"/>
                                    <span class="align-middle ml-50">
                                    {{ listMode === 'active' ? i18nT(`Archiver`) : i18nT(`Désarchiver`) }}
                                </span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="onDelete(data)"
                                >
                                    <RtIcon variant="light" icon="trash text-danger"/>
                                    <span class="align-middle ml-50 text-danger">
                                    {{ i18nT(`Supprimer`) }}
                                </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>

                </b-table>
            </div>
            <div class="mx-1 mb-2">
                <b-row v-if="inventories.length > 0">
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        cols="12"
                        sm="4"
                    >
                        <b-form-checkbox
                            v-model="selectAllStatus" class="d-inline-block mx-1"
                            @change="onSelectAll"
                        >
                            {{ i18nT(`Tout`)}}
                        </b-form-checkbox>
                        <b-dropdown
                            variant="outline-secondary"
                            size="sm"
                        >
                            <template #button-content>
                                <RtIcon variant="light" icon="download"/>
                                {{ i18nT(`Export`) }}
                            </template>
                            <b-dropdown-item class="text-primary"
                            >
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-excel"/>
                                    <span class="pl-1">
                                {{ i18nT(`Export format Excel`) }}
                                    </span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-lines"/>
                                    <span class="pl-1">{{ i18nT(`Export format OpenOffice`) }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col  cols="12" sm="4" class="d-flex align-items-center justify-content-center">
                        <span class="text-muted ml-1 d-none d-md-flex text-nowrap">
                        {{ i18nT(`Affichage de`) }} {{ dataMeta.from }} {{ i18nT(`à`) }}
                        {{ dataMeta.to }} {{ i18nT(`sur`) }} {{ dataMeta.of }}
                        {{ i18nT(`entrées`) }}
                        </span>
                    </b-col>
                    <!--                        Pagination-->
                    <b-col
                        cols="12"
                        sm="4"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalInventories"
                            class="mb-0 mt-1 mt-sm-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                        >
                            <template #prev-text>
                                <RtIcon variant="light" icon="chevron-left" :size="16"/>
                            </template>
                            <template #next-text>
                                <RtIcon variant="light" icon="chevron-right" :size="16"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

<!--                FLOAT ACTIONS-->
        <b-card no-body v-if="selectedItems.length>0" class="float-actions">
            <b-row class="">
                <b-col cols="12"
                       class="d-flex m-1 "
                >
                    <span class="d-none d-md-flex align-items-center mr-1">
                    {{i18nT(`Actions`)}}
                        </span>
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Supprimer la sélection`)"
                        @click="onBulkDelete"
                    >
                        <RtIcon :size="16" variant="light" icon="trash"/>
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "listMode === 'active' ? i18nT(`Archiver la sélection`) : i18nT(`Sortir des archives`)"
                        @click="onBulkArchive"
                    >
                        <RtIcon :size="16" variant="light" icon="box-archive"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
<!--        DOWNLOAD FILE MODAL-->
        <modal-download-file :downloadFileAction="downloadFileAction"></modal-download-file>
    </div>
</template>

<script>
import {
    BAvatar,
    BButton,
    BCard,
    BCardBody,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    BFormRadioGroup,
    BLink,
    BRow,
    VBTooltip,
} from "bootstrap-vue";
import RtSelect from "@core/components/RtSelect.vue";
import RtAllFilters from "@core/components/RtAllFilters.vue";
import useInventoriesList from "@/views/landlord/Inventories/tableInventoriesList";
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import ModalDownloadFile from "@/views/common/Documents/ModalDownloadFile.vue";
import useAuth from "@/auth/useAuth";
import {dictToSelectArray} from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from '@/store/index'



export default {
    components: {
        ModalDownloadFile,
        BRow,
        BDropdown,
        RtAllFilters,
        BFormRadioGroup,
        BDropdownItem,
        BButton,
        BCard,
        BCardBody,
        BFormCheckbox,
        BFormGroup,
        BCol,
        RtSelect,
        BAvatar,
        BLink,
        vSelect,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    setup(){
        const{
            refInventoriesListTable,
            tableColumns,
            perPage,
            totalInventories,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            inventories,
            inventoryLandlord,
            inventoryProperty,
            inventoryType,
            listMode,
            dataMeta,
            activeInventoriesCount,
            archiveInventoriesCount,
            fetchInventories,
            refetchData,
        } = useInventoriesList();

        return {
            refInventoriesListTable,
            tableColumns,
            perPage,
            totalInventories,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            inventories,
            inventoryLandlord,
            inventoryProperty,
            inventoryType,
            listMode,
            dataMeta,
            activeInventoriesCount,
            archiveInventoriesCount,
            fetchInventories,
            refetchData,
        }
    },
    data() {
        return{
            inventoryLandlords: [],
            inventoryTypes: [],
            inventoryProperties: [],
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            downloadFileAction: [],
            availableFields:[
                {label: this.i18nT(`Type`), key: 'HandoverType', visible: true},
                {label: this.i18nT(`Bien`), key: 'PropertyTitle', visible: true},
                {label: this.i18nT(`Locataire`), key: 'tenant', visible: true},
                {label: this.i18nT(`Modèles`), key: 'templates', visible: true},
            ],

        }
    },
    created(){
        let storePreferences = useAuth.getColumnPreferences('inventories')
        if(storePreferences) {
            this.availableFields = storePreferences
        }

        this.getFilters()
    },
    watch: {
        inventoryLandlord: {
            handler() {
                this.getFilters()
            },
            deep: true
        }
    },
    methods: {
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.LeaseHandoverID)
            } else {
                this.selectedItems = []
            }
        },
        onArchive(inventory) {
            const type = this.listMode === 'active' ? 'archive' : 'unarchive'

            this.$http.post(`landlord/handovers/${type}?id=${inventory.item.LeaseHandoverID}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Archivé avec succès`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
                this.refetchData()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
                this.refetchData()
            })
        },
        onBulkArchive() {
            const type = this.listMode === 'active' ? 'massArchive' : 'massUnarchive'

            this.$http.post(`landlord/handovers/${type}?ids=${this.selectedItems.join(',')}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Archivé avec succès!`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
            .catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
                this.refetchData()
            })
        },
        onDelete(inventory) {
            this.$http.delete(`landlord/handovers?id=${inventory.item.LeaseHandoverID}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Supprimé avec succès!`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
                this.refetchData()
            })
            .catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
                this.refetchData()
            })
        },
        onBulkDelete() {
            this.$http.post(`landlord/handovers/massDelete?ids=${this.selectedItems.join(',')}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Supprimé avec succès!`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
            .catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
                this.refetchData()
            })
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        clearFilters() {
            this.inventoryType = ''
            this.inventoryProperty = [];
            this.inventoryLandlord = [];
        },
        removeFilter(filter) {
            const multiple = typeof(this[filter.originalArray]) == 'object'
            
            if(!multiple) {
                this[filter.originalArray] = ''
            } else {
                this[filter.originalArray] = this[filter.originalArray].filter(item => item !== filter.value)
            }
        },
        getFilters() {

            const landlordFilters = this.inventoryLandlord.length > 0 ? "?LandlordID=" + this.inventoryLandlord.join(',') : ""

            this.$http.get(`landlord/handovers/filters${landlordFilters}`)
            .then(({data}) => {

                this.inventoryLandlords = dictToSelectArray(data.data.landlords);
                this.inventoryLandlords.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                })

                const properties = dictToSelectArray(data.data.properties);
                const buildings = dictToSelectArray(data.data.buildings);

                properties.unshift({
                    value: 0,
                    text: this.i18nT(  `-- BIENS --`),
                    disabled: true,
                });

                buildings.unshift({
                    value: 0,
                    text: this.i18nT(  `-- IMMEUBLES --`),
                    disabled: true
                });

                this.inventoryProperties = [...properties, ...buildings];

                this.inventoryProperties.map(property=> ({...property, disabled: property.disabled ? true : false}))
                this.inventoryProperties.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                });

                this.inventoryTypes = dictToSelectArray(data.data['handover_types']);
                this.inventoryTypes.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                });
            })
        },
        getBadgeTitle(item){
            if(item.LeaseHandoverSignStarted && item.LeaseHandoverSignDate){
                return this.i18nT(`Signé le`)
            }
            if(item.LeaseHandoverSignStarted && !item.LeaseHandoverSignDate){
                return this.i18nT(`En attente de signature`)
            }
            else {
                return this.i18nT(`Brouillon`)
            }
            //TODO
        },
        openDownloadModal(file) {
            this.$bvModal.show('modal-download-file')
        },
    },
    computed: {
        emptyHtml() {
            let imgPath = store.state.appConfig.layout.skin === 'dark'

            return "<img src='" + require(imgPath ? '@client/images/errorDark.svg' : '@client/images/error.svg')
                + "' alt='Error page' class='img-fluid' width='300'><br/><h2>"
                + this.i18nT(`Il n'y a rien par ici ...`) + "</h2><p>"
                + this.i18nT(`Cette page permet de gérer les états des lieux d'entrée et de sortie.`) + "</p>"
                +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" +"properties/create-property/general-info\"'>" + this.i18nT(`Nouvel état des lieux`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.availableFields.length; i++) {
                if(!this.availableFields[i].visible) {
                    col = col.filter(c => c.key !== this.availableFields[i].key)
                }
            }
            useAuth.setColumnPreferences('inventories', this.availableFields);

            return col;
        },
    }

}
</script>

<style>



 .rt-hidden-button {
     width: 30px;
     height: 15px;
 }

</style>


