import axios from '@axios';
import { ref, watch, computed } from '@vue/composition-api'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import router from "@/router";
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
};

export default function useInventoriesList() {

	const refInventoriesListTable = ref(null);

	const tableColumns = [
		{key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
		{key: 'HandoverIdentifiant', label: i18nT(`Identifiant`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
		{key: 'HandoverType', label: i18nT(`Type`), sortable: true },
		{key: 'PropertyTitle', label: i18nT(`Bien`), sortable: true },
		{key: 'tenant', label: i18nT(`Locataire`), sortable: false },
		{key: 'templates', label: i18nT(`Modèles`), sortable: false },
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'sticky-column',
			stickyColumn: true
		},
	]

	const perPage = ref(10);
	const totalInventories = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('');
	const isSortDirDesc = ref(true);
	const inventories = ref([]);
	const inventoryLandlord = ref([]);
	const inventoryProperty = ref([]);
	const inventoryType = ref('');
	const listMode = ref('active');
	const activeInventoriesCount = ref(0);
	const archiveInventoriesCount = ref(0);

	const queries = router.currentRoute.query;


	if(queries.currentPage){
		currentPage.value = queries.currentPage;
	}

	if(queries.inventoryType){
		inventoryType.value = queries.inventoryType;
	}

	if(queries.inventoryLandlord){
		inventoryLandlord.value = queries.inventoryLandlord.split(',');
	}

	if(queries.inventoryProperty){
		inventoryProperty.value = queries.inventoryProperty.split(',');
	}

	if(queries.archive){
		listMode.value = 'archive';
	}

	const dataMeta = computed(()=> {
		const localItemsCount = refInventoriesListTable.value
			? refInventoriesListTable.value.localItems.length
			: 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalInventories.value,
		}
	})

	let timeoutId = null;

	const refetchData = () => {

		const query = {};

		if(currentPage.value !== 1) {
			query.currentPage = currentPage.value;
		}

		if(inventoryType.value !== '' && inventoryType.value != '-1') {
			query.inventoryType = inventoryType.value;
		}

		if(inventoryLandlord.value.length > 0 && !(inventoryLandlord.value.includes('-1'))) {
			query.inventoryLandlord = inventoryLandlord.value.join(',');
		}

		if(inventoryProperty.value.length > 0 && !(inventoryProperty.value.includes('-1'))) {
			query.inventoryProperty = inventoryProperty.value.join(',');
		}

		if(listMode.value === 'archive') {
			query.archive = '1';
		}

		const queryString = new URLSearchParams(query).toString();
		if(queryString !== '') {
			history.pushState({}, '', '?' + queryString);
		} else {
			history.pushState({}, '', location.pathname);
		}

		refInventoriesListTable.value.refresh();
	}

	watch(
		[
			currentPage,
			perPage,
			inventoryProperty,
			inventoryType,
			inventoryLandlord,
			listMode,
		],
		() => {
			refetchData();
		}
	)

	watch (
		searchQuery,
		() => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				refetchData();
			}, 500);
		}
	)

	const fetchInventories = (ctx, callback) => {
		const params = {
			page: currentPage.value,
			show_per_page:perPage.value,
			sort_by: sortBy.value,
			sort_oder: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value,
		}

		if (inventoryType.value !== '' && inventoryType.value != '-1') {
			params['filter[HandoverType]'] = inventoryType.value
		}

		if(inventoryProperty.value.length > 0 && !(inventoryProperty.value.includes('-1'))) {
			params['filter[PropertyID]'] = inventoryProperty.value.join(',')
		}

		if(inventoryLandlord.value.length > 0 && !(inventoryLandlord.value.includes('-1'))) {
			params['filter[LandlordID]'] = inventoryLandlord.value.join(',')
		}

		if(listMode.value === 'active') {
			params['active'] = 1;
		}

		axios.get('landlord/handovers', {
			params
		}). then(({data})=>{
			if(data.data.handovers) {
				inventories.value = data.data.handovers;
				callback(data.data.handovers);
			} else {
				inventories.value = []
				callback([])
			}
			totalInventories.value = data.data.pagination['records_count']
			activeInventoriesCount.value = data.data['records_count_active'];
			archiveInventoriesCount.value = data.data['records_count_archive'];
		})

	}

	return {
		refInventoriesListTable,
		tableColumns,
		perPage,
		totalInventories,
		currentPage,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		inventories,
		inventoryLandlord,
		inventoryProperty,
		inventoryType,
		listMode,
		dataMeta,
		activeInventoriesCount,
		archiveInventoriesCount,
		fetchInventories,
		refetchData,
	}
}